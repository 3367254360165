<template>
    <div>
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="ruleForm" :model="listQuery">
                    <div class="form">
                        <div class="search-conditions">   
                            <el-form-item label="关键字" prop="keyword">
                                <el-input class="input-w210-h32" :maxlength="120" size="large" v-model="listQuery.keyword" placeholder="请输入关键字" @keyup.enter.native="getList('search')"></el-input>
                            </el-form-item>                          
                            <el-form-item label="时间范围" prop="startTime"  class="clear-margin-right">
                                <el-date-picker class="date-w210-h32" align="right" size="small" v-model="listQuery.startTime" type="datetime" placeholder="请选择开始时间"
                                    value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerStartDateAlart"></el-date-picker>
                                <span class="time-interval">至</span>
                            </el-form-item>
                            <el-form-item label="" prop="endTime">
                                <el-date-picker class="date-w210-h32" align="right" size="small" v-model="listQuery.endTime" type="datetime" :default-time="new Date()+''"
                                    placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerEndDateAlart"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="状态" prop="bindState">
                              <el-select clearable class="input-w210-h32 select-customize-input" popper-class="select-customize" v-model="listQuery.bindState"  placeholder="请选择">
                                <el-option
                                  v-for="item in options"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item class="clear-margin-bottom clear-margin-right" label="" prop="childFlag"></el-form-item>
                            <tem-btn  type="primary" value="查询" @click="getList('search')"></tem-btn>
                            <tem-btn  type="info" value="清空" @click="resetForm('ruleForm')"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>

        <div class="box clearfix">
            <div class="left" v-if="showFlag">
                <div>选择组织机构</div>
                <Tree :nodeClick="currentNode" ></Tree>
            </div>
            <div :class='[showFlag?"":"noLeft","right"]' >
                <div class="table-list">
                    <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize" :orderBy.sync="listQuery.orderField" :sortIndex.sync="listQuery.sort" @pagination="getList"></Table>
                </div>
            </div>
        </div>
        <div class="copyright">Copyright©2018-{{(new Date()).getFullYear()}} 重庆几米信息技术有限公司 版权所有</div>
        <el-dialog 
            title="生成挪车码"
            :visible.sync="dialogVisible2"
            width="424px">
            <el-form :model="dialogInfo2" :rules="rules" ref="dialogInfo2" label-width="68px" class="data-form" @submit.native.prevent>
                <el-form-item label="组织机构" prop="orgName" class="el-w292"  v-if="showFlag">
                    <popover @nodeClick="nodeClick" v-model="openOrClose" ref="tree" :data="treeData"  @hide="openOrClose = false" @show="openOrClose = true">
                        <div slot="treeReference" >
                            <div class="org-input">
                                <el-input placeholder="请选择组织机构" class="input-w290-h32" disabled :class="openOrClose ? 'pop-open-style' : 'pop-close-style'" v-model="dialogInfo2.orgName"></el-input>
                                <svg-icon :class="{'svg-icon': true, 'icon-link': true, 'icon-transform': !openOrClose} " icon-class="icon-select" size="16"></svg-icon>
                            </div>
                        </div>
                    </popover>
                </el-form-item>
                <el-form-item label="数量" prop="amount">
                    <el-input class="input-w290-h32" v-model="dialogInfo2.amount" @keyup.native="number"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn  type="info" @click="dialogVisible2 = false"  value="取消"></tem-btn>
                <tem-btn  type="primary" @click=createMovecarCode  value="确定"></tem-btn>
            </div>
        </el-dialog>
        <el-dialog 
            title="查看详情"
            :visible.sync="dialogVisible"
            width="424px">
            <el-form :model="dialogInfo" :rules="rules2" label-width="68px" class="data-form">
                <el-form-item label="车主名" prop="driverName">
                    <el-input maxlength="10" class="input-w290-h32" v-model="dialogInfo.driverName" ></el-input>
                </el-form-item>
                <el-form-item label="车牌号" prop="carBrand">
                    <el-input maxlength="10" class="input-w290-h32" v-model="dialogInfo.carBrand" ></el-input>
                </el-form-item>
                <el-form-item label="车型" prop="carModel">
                    <el-input maxlength="20" class="input-w290-h32" v-model="dialogInfo.carModel" ></el-input>
                </el-form-item>
                <el-form-item label="购车方式" prop="payType">
                    <el-select class="input-w290-h32 select-customize-input" popper-class="select-customize" v-model="dialogInfo.payType"  placeholder="请选择">
                        <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="贷款金额" prop="loanMoney" v-if="dialogInfo.payType==2">
                    <el-input maxlength="10" class="input-w290-h32"  v-model="dialogInfo.loanMoney" onkeyup="this.value = this.value.replace(/[^\d.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="贷款周期" prop="loanCycle" v-if="dialogInfo.payType==2">
                    <el-select clearable class="input-w290-h32 select-customize-input" popper-class="select-customize" v-model="dialogInfo.loanCycle"  placeholder="请选择">
                        <el-option
                            v-for="item in options3"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input class="input-w290-h32" v-model="dialogInfo.remark"  maxlength="100"></el-input>
                    <!-- <el-input class="input-w290-h32" type="textarea" v-model="dialogInfo.remark"  maxlength="100" :rows="7" show-word-limit></el-input> -->
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn  type="info" @click="dialogVisible = false"  value="关闭"></tem-btn>
                <tem-btn  type="primary" @click=addOrModifyVisitInfo  value="确定"></tem-btn>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import popover  from '@/components/popover/index'
import Tree from "@/components/tree/tree"; //  树
import Table from '@/components/table/table';
import {listPageVagueDeviceInfo,exportMovecarCode,modifyVisitInfo,createMovecarCode,getVisitInfo,addVisitInfo} from "@/api/QRCodeManagement";
import { organizationTree } from '@/api/systemSetting';
import {getOrgId} from '@/utils/cookie';
    export default {
        components: {
            Table,
            Tree,
            popover
        },
        data() {
            return {
                showFlag:true,
                flag:"add",
                rules:{
                    orgName:[{ required: true,type: 'string', message: '请选择组织机构', trigger: 'change' }],
                    amount:[ { required: true, message: '请输入数量'} ]
                },
                rules2:{
                    // loanMoney:[ {  message: '请输入数量'} ], 
                    // desc: [{ min: 0, max: 100, message: '备注100个字符以内', trigger: 'change' }]
                },
                openOrClose:false,
                treeData:[],
                dialogVisible:false,
                dialogVisible2:false,
                dialogInfo:{
                    driverName:'',
                    carBrand:'',
                    carModel:'',
                    payType:'',
                    loanMoney:'',
                    loanCycle:'',
                    remark:'',
                    imei:''
                },
                dialogInfo2:{
                    amount:'',
                    orgId:'',
                    orgName:''
                },
                pickerStartDateAlart: {
                    disabledDate: (time) => { // 限制开始时间小于结束时间
                        if (this.listQuery.endTime) {
                            if(this.listQuery.endTime.split(' ')[1] ==  '00:00:00') {
                                return time.getTime() > new Date(this.listQuery.endTime.split(' ')[0]).getTime()|| time.getTime() > Date.now();
                            } else {
                                return time.getTime() > new Date(this.listQuery.endTime.split(' ')[0]).getTime()|| time.getTime() > Date.now();
                            }
                        } else {
                            return time.getTime() > Date.now();
                        }
                    }
                },
                // 结束时间
                pickerEndDateAlart: {
                    disabledDate: (time) => { // 限制开始时间小于结束时间
                    
                        if (this.listQuery.startTime) {
                            if( this.listQuery.startTime.split(' ')[1] ==  '00:00:00' || this.listQuery.startTime.split(' ')[0] == this.format(new Date(),'-').split(' ')[0]) {
                                return  time.getTime() < new Date(this.listQuery.startTime.split(' ')[0]).getTime() - 8.64e7 || time.getTime() > Date.now();
                            }  else {
                                return time.getTime() < new Date(this.listQuery.startTime.split(' ')[0]).getTime() - 8.64e7 || time.getTime() > Date.now();
                            }
                            
                        } else {
                            return time.getTime() > Date.now();
                        }
                    }
                },
                listQuery:{
                    orgId:'',
                    keyword:'',
                    bindState:'',
                    startTime:'',
                    endTime:'',
                    total:10000,
                    pageIndex:1,
                    pageSize:10,
                    orderField:null,
                    sort:''
                },
                options: [{
                    value: '',
                    label: '全部'
                    }, {
                    value: '1',
                    label: '已绑定'
                    }, {
                    value: '2',
                    label: '未绑定'
                }],
                options2: [{
                    value: 1,
                    label: '全款'
                    }, {
                    value: 2,
                    label: '贷款'
                    }],
                options3: [{
                    value: 12,
                    label: '12期'
                    },{
                    value: 24,
                    label: '24期'
                    }, {
                    value: 36,
                    label: '36期'
                    },{
                    value: 48,
                    label: '48期'
                    }, {
                    value: 60,
                    label: '60期'
                    }],
                table: { //表格配置对象
                    configItem: true, //是否开启表格项配置
                    stripe: false, //是否为斑马纹
                    loading: false, // 数据加载loading
                    data: [], //表格渲染数据
                    //表头按钮
                    operateBtns:[
                        {
                            value:'生成挪车码',
                            type:'primary',
                            event:() => {

                                this.$nextTick(()=>{
                                    this.resetForm("dialogInfo2")
                                })
                                this.dialogVisible2 = true;
                            }
                        },
                        {
                            value:'导出EXCEL',
                            type:'info',
                            event:() => {
                                this.exportMovecarCode()
                            }
                        },
                    ],
                    header: [{
                        type: 'index',
                        label: '序号',
                        width: '100',
                        ifShow: true,
                    },
                    {
                        prop: 'groupNum',
                        label: '批次号',
                        minWidth: '190',
                        tooltip: true,
                        ifShow: true,
                    },
                    {
                        prop: 'imei',
                        label: '挪车码',
                        minWidth: '180',
                        ifShow: true,
                        tooltip: true
                    },
                    {
                        prop: 'orgName',
                        label: '机构',
                        minWidth: '180',
                        tooltip: true,
                        ifShow: true,
                    },            
                    {
                        prop: 'linkPhone',
                        label: '绑定电话',
                        minWidth: '180',
                        ifShow: true,
                        tooltip: true
                    },
                    {
                        prop: 'createTime',
                        label: '生成时间',
                        minWidth: '190',
                        sortable: 'column',
                        ifShow: true,
                        tooltip: true
                    },
                    {
                        prop: 'expireDate',
                        label: '有效期',
                        minWidth: '190',
                        sortable: 'column',
                        ifShow: true,
                        tooltip: true
                    },
                    {
                        prop: 'bindState',
                        label: '状态',
                        minWidth: '180',
                        ifShow: true,
                        tooltip: true,
                        render: (h,param) => {
                            var text = "";
                            if(param.bindState == 1){
                                text= '已绑定'
                            }else if(param.bindState == 2){
                                text= '未绑定'
                            }
                            return h('div', {},text)
                        }
                    },
                    {
                        prop: 'bindTime',
                        label: '首次绑定时间',
                        minWidth: '190',
                        sortable: 'column',
                        ifShow: true,
                        tooltip: true
                    },
                    {
                        prop: 'options',
                        label: '操作',
                        minWidth: '180',
                        ifShow: true,
                        align: 'center',
                        fixed: 'right',
                        render:(h,param) => {
                            return h('div', [
                                h(
                                    'el-link', {
                                        props: {
                                            type:'primary',
                                        },
                                        style: {
                                            color: '#3270FF'
                                        },
                                        on:{
                                            click:()=> {
                                                // this.$nextTick(()=>{
                                                //     this.resetForm("dialogInfo2")
                                                // })
                                                this.dialogInfo.imei = param.imei;
                                                this.getVisitInfo(param.imei)
                                            }
                                        }
                                    },'回访记录'
                                )
                            ]);
                        }
                    }]
                },
            }
        },
        mounted() {
            let s = getOrgId();
            if(s != 1){
                this.listQuery.orgId = getOrgId();   
                this.dialogInfo2.orgId = getOrgId();
                this.showFlag = false;
            }
        },
        created(){
            this.getList();
            this.getUserTreeInfo();
        },
        watch: {
            'listQuery.startTime': {
                handler(newValue, oldValue) {
                    if (newValue) {
                        let nowDate = this.format(new Date(), '-').split(' ');//当天时间
                        let endTime = this.listQuery.endTime ? this.listQuery.endTime.split(' ') : ''; //开始时间
                        let timeStr = '';
                        //判断是否选择结束时间
                        if(this.listQuery.endTime) {
                            //判断开始时候是不是今天
                            let time = new Date(this.listQuery.endTime);
                            //加23:59:59
                            let newTimeStr = new Date(time.setHours(time.getHours()-24))
                            let formatTime = this.format(newTimeStr,'-');
                            //判断是否跨天
                            let newFormat = newValue.split(' ');
                            if(endTime[0] == formatTime.split(' ')[0] || endTime[0] == newFormat[0]){
                                if (endTime[1] == '00:00:00') {
                                    timeStr = '00:00:00'  + ' - 23:59:59';
                                } else {
                                    timeStr = '00:00:00 - '  + endTime[1];
                                }
                                console.log(timeStr,9999);
                            } else {
                                //跨天
                                timeStr = endTime[1] + ' - 23:59:59';
                            }
                        } else {
                            if(newValue.split(' ')[0] == nowDate[0]) {
                                timeStr = '00:00:00 - '  + nowDate[1];
                            } else {
                                timeStr = '00:00:00 - 23:59:59';
                            }
                        }
                        this.startTimeRange = timeStr;
                    }
                },
            },
            'listQuery.endTime': {
                handler(newValue, oldValue) {
                    if (newValue) {
                        let nowDate = this.format(new Date(), '-').split(' ');//当天时间
                        let startTime = this.listQuery.startTime ? this.listQuery.startTime.split(' ') : ''; //开始时间
                        let timeStr = '';
                        //判断是否选择开始时间
                        if(this.listQuery.startTime) {
                            //判断开始时候是不是今天
                            if(startTime[0] == nowDate[0]) {
                                timeStr = startTime[1] + ' -' + nowDate[1];
                            } else {
                                let time = new Date(this.listQuery.startTime);
                                //加23:59:59
                                let newTimeStr = new Date(time.setHours(time.getHours()+24)-1000)
                                let formatTime = this.format(newTimeStr,'-');
                                //判断是否跨天
                                let newFormat = newValue.split(' ');
                                if(startTime[0] == formatTime.split(' ')[0] || startTime[0] == newFormat[0]){
                                    timeStr = startTime[1]  + ' - 23:59:59';
                                } else {
                                    //跨天
                                    timeStr = '00:00:00 - '  + startTime[1];
                                }
                            }
                        } else {
                            if(newValue.split(' ')[0] == nowDate[0]) {
                                timeStr = '00:00:00 - '  + nowDate[1];
                            } else {
                                // timeStr = '00:00:00 - 23:59:59';
                            }
                        }
                        this.endTimeRange = timeStr;
                    }
                },
            },
        },
        methods: {
            addOrModifyVisitInfo(obj){
                console.log(this.dialogInfo)
                if(this.flag == "add"){
                    
                    addVisitInfo(this.dialogInfo).then(res => {
                        this.dialogVisible = false;
                    })
                    
                }else{
                    modifyVisitInfo(this.dialogInfo).then(res => {
                        this.dialogVisible = false;
                    })
                }
            },
            getVisitInfo(imei){
                getVisitInfo({imei:imei}).then(res => {
                    if(res.data){
                        //有数据
                        this.flag = "edit"
                        this.dialogInfo.driverName = res.data.driverName;
                        this.dialogInfo.carBrand = res.data.carBrand;
                        this.dialogInfo.carModel = res.data.carModel;
                        this.dialogInfo.payType = res.data.payType;
                        this.dialogInfo.loanMoney = res.data.loanMoney;
                        this.dialogInfo.loanCycle = res.data.loanCycle;
                        this.dialogInfo.remark = res.data.remark;
                        // this.dialogInfo.imei = res.data.imei;
                        this.dialogInfo.visitId = res.data.visitId;
                    }else{
                        //无数据
                        this.flag = "add"
                        this.dialogInfo.driverName = '';
                        this.dialogInfo.carBrand = '';
                        this.dialogInfo.carModel = '';
                        this.dialogInfo.payType = '';
                        this.dialogInfo.loanMoney = '';
                        this.dialogInfo.loanCycle = '';
                        this.dialogInfo.remark = '';
                        // this.dialogInfo.imei = '';
                        this.dialogInfo.visitId = '';
                    }
                    this.dialogVisible = true;
                })
            },
            number() {
                this.dialogInfo2.amount = this.dialogInfo2.amount.replace(/[^\.\d]/g,'');
                this.dialogInfo2.amount = this.dialogInfo2.amount.replace(/\D|^0/g,'')
                this.dialogInfo2.amount = this.dialogInfo2.amount.replace('.','');
            },
            createMovecarCode(){
                var _ = this;
                this.$refs.dialogInfo2.validate((valid) => {
                    if (valid) {
                        createMovecarCode(_.dialogInfo2).then(res => {
                            if (res.code == 200) {
                                // _.$message.success('操作成功');
                                this.$alert('批次号:'+res.data, '操作成功', {
                                    confirmButtonText: '确定',
                                    callback: action => {
                                        // this.$message({
                                        //     type: 'info',
                                        //     message: `action: ${ action }`
                                        // });
                                    }
                                });
                                this.getList();
                            } else {
                                _.$message.error('操作失败');
                            }
                            _.dialogVisible2 = false;
                        })
                    }
                })
            },
            nodeClick(obj,flag){
                this.$set( this.dialogInfo2,'orgId',obj.orgId);
                this.$set( this.dialogInfo2,'orgName',obj.orgName);
                //双击关闭popover
                if(flag == 'db') {
                    this.openOrClose = false;
                }
            },
            //获取数据
            getUserTreeInfo(){
                organizationTree({}).then(res => {
                    this.treeData = res.data
                })
            },
            currentNode(data) {
                this.listQuery.orgId = data.orgId;
                this.listQuery.pageIndex = 1;
                this.listQuery.pageSize = 10;
                this.getList();
            },
             // 时间补0
            supplement(nn) {
                return (nn = nn < 10 ? "0" + nn : nn);
            },
            // 转换时间格式
            format(Date, str) {
                // debugger
                var obj = {
                    Y: Date.getFullYear(),
                    M: Date.getMonth() + 1,
                    D: Date.getDate(),
                    H: Date.getHours(),
                    Mi: Date.getMinutes(),
                    S: Date.getSeconds(),
                };
                // 拼接时间 hh:mm:ss
                var time =
                    " " +
                    this.supplement(obj.H) +
                    ":" +
                    this.supplement(obj.Mi) +
                    ":" +
                    this.supplement(obj.S);
                // yyyy-mm-dd
                if (str.indexOf("-") > -1) {
                    return (
                    obj.Y +
                    "-" +
                    this.supplement(obj.M) +
                    "-" +
                    this.supplement(obj.D) +
                    time
                    );
                }
                // yyyy/mm/dd
                if (str.indexOf("/") > -1) {
                    return (
                    obj.Y +
                    "/" +
                    this.supplement(obj.M) +
                    "/" +
                    this.supplement(obj.D) +
                    time
                    );
                }
            },
            getList(val){
                if(val == "search"){
                    this.listQuery.pageIndex =1 ;
                }
                if ((!this.listQuery.startTime && this.listQuery.endTime) || (this.listQuery.startTime && !this.listQuery.endTime)) {
                    this.$message({type:'warning',message:'请重新选择完整的时间！'})
                    return;
                }
                if (this.listQuery.startTime > this.listQuery.endTime) {
                    this.$message({type:'warning',message:'开始时间大于结束时间，请重新选择！'})
                    return;
                }
                listPageVagueDeviceInfo(this.listQuery).then(res => {
                    // console.log(res)
                    this.table.data = res.data;
                    this.listQuery.total = res.count; 
                })
            },
            resetForm(formName) { 
                if(this.$refs[formName]){
                    this.$refs[formName].resetFields();
                }
            },
            /** 导出文件 */
            exportMovecarCode() {
                exportMovecarCode(this.listQuery).then( res => {
                    // 使用blob下载文件
                    const blob = new Blob([res]);
                    const downloadElement = document.createElement('a');
                    const href = window.URL.createObjectURL(blob);
                    downloadElement.href = href;
                    // 转换文件文字为中文
                    downloadElement.download = '挪车码记录.xlsx';
                    document.body.appendChild(downloadElement);
                    downloadElement.click();
                    document.body.removeChild(downloadElement); // 下载完成移除元素
                    window.URL.revokeObjectURL(href); // 释放掉blob对象
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
.clearfix:after{
     content:".";        
     display:block;        
     height:0;        
     clear:both;        
     visibility:hidden;        
}
.left {
    float: left;
    width: 300px;
    background-color: #fff;
    border-radius: 10px;
    // min-height: 500px;
    // border: 1px solid #487efd;
    padding: 10px;
}
.right {
    margin-left: 40px;
    float: left;
    width:calc(100% - 360px); 
    // height: 100%;
}
.right.noLeft{
    width:100%;
    margin-left: 0;
}
</style>
