import request from '@/utils/request';

/** 分页查询 */
export function listPageVagueDeviceInfo(data) {
    return request({
        url: '/movecar/deviceInfo/listPageVagueDeviceInfo',//请求路径
        method: 'get',
        params:data
    });
}
/** 导出挪车码 */
export function exportMovecarCode(data) {
    return request({
        url: '/movecar/deviceInfo/exportMovecarCode',//请求路径
        method: 'get',
        params:data,
        responseType: 'blob'    //重点重点，，位置要写对
    });
}
/** 修改回访信息 */
export function modifyVisitInfo(data) {
    return request({
        url: '/movecar/visitInfo/modifyVisitInfo',//请求路径
        method: 'post',
        data
    });
}
/** 生成挪车码 */
export function createMovecarCode(data) {
    return request({
        url: '/movecar/deviceInfo/createMovecarCode',//请求路径
        method: 'post',
        data
    });
}
/** 查询回访信息明细信息 */
export function getVisitInfo(data) {
    return request({
        url: '/movecar/visitInfo/getVisitInfo',//请求路径
        method: 'get',
        params:data
    });
}
/** 新增回访信息 */
export function addVisitInfo(data) {
    return request({
        url: '/movecar/visitInfo/addVisitInfo',//请求路径
        method: 'POST',
        data
    });
}